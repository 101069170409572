$(document).ready(function () {
  // open-sidebar End
  $(document).on("click", ".dropdown-toggle", function () {
    $(this).next().toggleClass("show");
    $(this).parent().siblings().children(".dropdown-menu").removeClass("show");
  });

  //HIDE OPTIONS IF CLICKED ANYWHERE ELSE ON PAGE
  $(document).bind('click', function(e) {
    var $clicked = $(e.target);
    if (! $clicked.parents().hasClass("dropdown")) {
      if($( ".dropdown .dropdown-menu-right" ).hasClass( "show" )) {
        $(".dropdown .show").removeClass('show');
        $( ".dropdown-togg" ).click();
      }
    }
  });
});
